.popup {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0,0,0,0.8);
	z-index: 100;
	overflow: hidden;

	.popup-wrapper {
		position: relative;
		min-width: 300px;
		margin: 0 auto;
		box-sizing: border-box; }

	.popup-title:empty,
	.popup-count:empty {
		display: none !important; }

	.popup-holder img {
		max-width: 100%; }

	.popup-but-prev.disabled,
	.popup-but-next.disabled {
		display: none; }

	.popup-preload {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 60px;
		height: 60px;
		margin: auto;
		padding: 20px;
		background: url("../img/loading_w.gif") 50% 50% no-repeat;
		font-size: 0;
		opacity: .8;
		overflow: hidden;
		text-indent: 100%;
		text-align: center;
		z-index: 10; }

	.popup-error {
		position: relative;
		padding: 40px 0;
		text-align: center; }

	&.t-1 {
		.f-stand-item & {
			background-color: #000; }

		.stand-holder {

			img {
				transition: filter .3s; } }
		.f-stand-item & {
			display: block !important;

			.stand-holder img {
				filter: blur(15px); } } }

	&.t-image,
	&.t-zoom {
		.popup-info,
		.popup-but-all-close,
		.popup-but-prev,
		.popup-but-next {
			display: none; } }
	&.t-zoom {
		.popup-content {
			text-align: center;
			margin: 0; } } }

.stand-holder {
	position: relative;
	left: 0;
	top: 0;

	.stand-pin {
		position: absolute;
		left: 140px;
		top: 340px;
		opacity: 0;

		.f-show-pin & {
			opacity: 1; }

		.f-stand-item & {
			display: none; }

		&:before,
		&:after {
			content: '';
			border-radius: 50%;
			position: absolute;
			top: 50%;
			left: 50%; }

		&:before {
			background: url('../img/stand-pin.png') 50% 50% no-repeat;
			background-color: rgb(237, 28, 36);
			box-shadow: 0px 10px 18px 0px rgba(0, 0, 0, 0.4);
			width: 22px;
			height: 22px;
			margin-top: -11px;
			margin-left: -11px; }
		&:after {
			border-radius: 50%;
			background-color: rgba(237, 28, 36, 0.30);
			width: 40px;
			height: 40px;
			margin-top: -20px;
			margin-left: -20px;
			transition: .3s; }

		&:hover:after {
			animation: pinBomb 1s infinite ease-out; } } }

@keyframes pinBomb {
	0% {
		transform: scale(1); }
	80% {
		transform: scale(2); }
	82% {
		transform: scale(1); } }

.stand-item {
	color: #fff;

	.slide {
		padding: 40px 20px;
		display: none;
		width: 100%;
		box-sizing: border-box;
		// min-height: 480px;
		opacity: .8;
		&:after {
			content: '';
			display: table;
			clear: both; }
		&.f-active {
			display: block;
			// &.t-small-content
			// 	display: table

			// 	> *
			// 		display: table-cell
			// 		vertical-align: middle

			/// .stand-item__text
 } }			/// 	width: 100%

	&__img {
		position: relative;
		// display: table-cell
		// vertical-align: middle;
		float: left;
		z-index: 4;
		min-width: 400px;
		max-width: 480px;
		padding: 12px;
		text-align: center;

		&.singe-img {
			float: none;
			max-width: 100%; }

		a {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			z-index: 2; }

		.icon {
			display: block;
			width: 60px;
			height: 60px;
			opacity: .4;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translateY(-50%) translateX(-50%);
			z-index: 1;
			transition: .3s; }

		a:hover .icon {
			opacity: 1; }

		& + .stand-item__text {
			// display: table-cell
			// vertical-align: middle
			// width: 100%
			margin: 0;
			max-width: 9999px; } }

	&__text {
		max-width: 660px;
		margin: 0 auto;
		padding: 20px;
		font-size: 16px;
		line-height: 1.4; }
	&__controls {
		position: fixed;
		padding-bottom: 60px;
		bottom: 0;
		left: 0;
		right: 0; }
	&__nav {
		text-align: center;
		white-space: nowrap;
		overflow-x: hidden;
		height: 160px;
		background: #000;

		img {
			height: 160px; }

		a {
			display: inline-block;
			min-height: 140px;
			opacity: .3;
			&:hover,
			&.f-active {
				opacity: 1; }
			&.f-active {
				cursor: default; } } }
	&__progress {
		background-color: #1b1d1e;
		padding: 10px 0;
		.wrapper {
			display: flex; } } }

.progress-bar {
	position: relative;
	padding-top: 20px;
	flex-grow: 1;
	// padding-right: 20px
	margin-right: 20px;

	.mCS-rounded.mCSB_scrollTools {
		top: 11px !important;
		bottom: auto !important;

		& + .progress-bar__line {
			display: none; }

		.mCSB_dragger {
			cursor: default; } }

	&.f-active {
		.mCS-rounded.mCSB_scrollTools {
			.mCSB_dragger_bar,
			.mCSB_dragger_bar:hover,
			.mCSB_dragger_onDrag .mCSB_dragger_bar,
			.mCSB_draggerRail {
				background-color: $active-color; }
			.mCSB_dragger {
				cursor: pointer; } } }

	&__line {
		position: relative;
		background-color: $text-color;
		height: 2px;
		width: 100%;

		.progress-bar.f-active & {
			background-color: $active-color; }

		span {
			position: absolute;
			left: 0;
			top: 0;
			width: 0;
			height: 2px;
			background-color: $text-color;
			.progress-bar.f-active & {
				background-color: $active-color; }

			&:after {
				position: absolute;
				content: '';
				right: 0;
				margin-right: -7px;
				top: 50%;
				margin-top: -7px;
				width: 14px;
				height: 14px;
				background-color: $text-color;
				border-radius: 50%;

				.progress-bar.f-active & {
					background-color: $active-color; } } } }

	&__info {
		text-align: center;
		font-size: 10px;
		font-weight: bold;
		color: $text-color;
		text-transform: uppercase;
		margin-top: 10px; }

	+ .b-contorl-btn {
		padding-left: 30px;
		border-left: 1px solid $text-color;
		color: $text-color;
		font-weight: bold;
		height: auto; } }
.popup-but-close {
	display: block;
	position: fixed;
	right: 40px;
	top: 40px;
	font-size: 0;
	width: 40px;
	height: 40px;
	// background: red
	&:hover {
			&:before,
			&:after {
				background: #00aeef; } }
	&:before,
	&:after {
		content: "";
		position: absolute;
		left: 19px;
		top: 0;
		width: 5px;
		height: 100%;
		background: #7a7e80;
 }		// border: 1px solid #7a7e80
	&:before {
		transform: rotate(45deg); }
	&:after {
		transform: rotate(-45deg); } }
.popup-but-all-close {
	display: none; }
