$text-color: #7a7e80;
$active-color: #00aeef;

.b-logo {
	position: relative;
	width: 242px;
	float: left;
	margin-right: 27px;
	font-family: 'SignPainterHouseScript', 'Roboto Condensed', 'Arial', 'Helvetica', sans-serif;

	a,
	img {
		display: block;
		width: 100%; } }

.b-menu {
	margin-top: 25px;
	float: left;

	ul {
		font-size: 0;

		li {
			display: inline-block;
			vertical-align: top; }

		li a {
			text-transform: uppercase;
			font-size: 16px;
			color: #fff;
			display: block;
			font-weight: 600;
			padding: 4px 20px; }

		li.selected a {
			border: 3px solid #fccc00;
			margin: -3px;
			color: #fccc00; }

		li:hover a {
			border: 3px solid #fccc00;
			margin: -3px;
			color: #fff; } } }

.b-head-ofsite {
	position: relative;
	// color: #dcdb9a
	display: table;
	vertical-align: middle;
	float: right; }

.b-gerb {
	display: table-cell;
	position: relative;
	background: url('../img/gerb.png') 0 50% no-repeat;
	padding: 5px 0 5px 60px;
	text-transform: uppercase;
	font-weight: bold;
	width: 220px; }

.site-exit {
	display: table-cell;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 12px;
	color: $text-color;
	height: 48px;
	vertical-align: middle;

	> * {
		display: inline-block;
		vertical-align: middle; }

	span {
		padding-right: 10px; }
	&:hover .icon {
		color: inherit; } }

.h-center {
	text-align: center; }
.h-left {
	text-align: left; }

.b-copyright {
	font-size: 12px;
	color: #e6e6e6;
	position: absolute;
	bottom: 30px;
	left: 20px; }

.b-footer-counter {
	min-height: 110px;
	margin-top: 50px;
	line-height: 110px;
	text-align: right;

	.bc-holder {
		width: 100%;
		line-height: normal;
		display: inline-block;
		vertical-align: bottom; }

	.bc-item {
		margin: 6px 0 0 6px;
		line-height: normal;
		display: inline-block;
		vertical-align: bottom; }

	.bc-item img {
		display: block; } }

.b-contorl-btn__item {
	position: relative; }
.e-move-left:before {
	content: "";
	position: absolute;
	left: -10px;
	top: 10px;
	width: 1px;
	height: 40px;
	background: #7A7E80; }
.e-move-down,
.e-scale-plus,
.e-play,
.e-pause {
	padding-right: 20px!important; }
.e-move-down:after,
.e-scale-plus:after,
.e-play:after,
.e-pause:after {
	content: "";
	position: absolute;
	right: 5px;
	top: 10px;
	width: 1px;
	height: 40px;
	background: #7A7E80; }
@media screen and (max-width: 1020px) {
	.b-footer-counter {
		min-height: 0;
		margin-top: 30px;
		line-height: normal;
		text-align: left;

		.bc-line {
			display: inline; } } }

@import 'includes/_loader';
@import 'includes/_popup';
@import 'includes/_icons';

.b-panorama {
	width: 100%;
	height: 100%;

	.bp-slide {
		float: left;
		width: 5000px;
		height: 980px;
		background-position: 0 50%;
		position: relative; }

	.bp-mark {
		position: absolute;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		background: #4686df;
		background: rgba(70,134,223, 0.6);
		border: 2px solid #fff;
		display: block;
		z-index: 10;
		text-align: center;
		line-height: 28px;

		&:hover:before {
			content: "";
			position: absolute;
			top: 2px;
			right: -33px;
			width: 40px;
			z-index: 0;
			border-top: 1px solid #fff;
			-o-transform-origin: 0 50%;
			-ms-transform-origin: 0 50%;
			-moz-transform-origin: 0 50%;
			-webkit-transform-origin: 0 50%;
			transform-origin: 0 50%;
			-o-transform: rotate(-45deg);
			-ms-transform: rotate(-45deg);
			-moz-transform: rotate(-45deg);
			-webkit-transform: rotate(-45deg);
			transform: rotate(-45deg); }

		&:hover {
			background: #4686df;
			background: rgba(70,134,223, 1); }

		&:after {
			content: '';
			position: absolute;
			bottom: 3px;
			right: -13px;
			z-index: 0;
			width: 17px;
			height: 4px;
			background: #fff;
			border-radius: 2px;
			-o-transform-origin: 0 50%;
			-ms-transform-origin: 0 50%;
			-moz-transform-origin: 0 50%;
			-webkit-transform-origin: 0 50%;
			transform-origin: 0 50%;
			-o-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			-moz-transform: rotate(45deg);
			-webkit-transform: rotate(45deg);
			transform: rotate(45deg); } }

	.bp-portal {
		position: absolute;
		width: 70px;
		height: 70px;
		border-radius: 50%;
		background: url("../img/icon_pl_p.png") 0 0 no-repeat;
		display: block;
		z-index: 20; }

	.bp-portal-left {
		background-position: 0 0; }

	.bp-portal-right {
		background-position: -70px 0; }

	.bp-portal-top {
		background-position: -140px 0; }

	.bp-portal-down {
		background-position: -210px 0; } }

.b-tip {
	position:absolute {}
	background: #4686df;
	border-radius: 4px;
	border: 1px solid #fff;
	top: 0;
	z-index: 12;
	display: none;
	padding: 10px 15px;
	font-size: 16px;
	color: #fff;
	white-space: nowrap; }

.b-choose-hall {
	counter-reset: list;
	float: left;

	a {
		position: relative;
		display: inline-block;
		padding-left: 10px;
		padding-right: 10px;
		margin-top: 10px;
		height: 36px;
		line-height: 36px;
		font-size: 10px;
		font-weight: bold;
		text-transform: uppercase;
		border: 1px solid rgb(122, 126, 128);

		&:before {
			display: block;
			position: absolute;
			bottom: -2px;
			left: 50%;
			margin-left: -10px;
			line-height: 10px;
			font-weight: normal;
			padding: 0 5px;
			background-color: #1b1d1e;
			counter-increment: list;
			content: counter(list) ""; }

		&:hover {
			color: #fff; }
		&.t-1 {
			.t-hall1 & {
				border-color: $active-color;
				color: rgb(153, 255, 255); } }
		&.t-2 {
			.t-hall2 & {
				border-color: $active-color;
				color: rgb(153, 255, 255); } }
		&.t-3 {
			.t-hall3 & {
				border-color: $active-color;
				color: rgb(153, 255, 255); } }
		&.t-4 {
			.t-hall4 & {
				border-color: $active-color;
				color: rgb(153, 255, 255); } } }

	a + a {
		margin-left: 15px; } }

.e-play {
	display: none !important; }

body {
	&.f-pause {
		.e-play {
			display: table-cell !important; }
		.e-pause {
			display: none !important; } }

	&.f-popup {
		.e-play,
		.e-pause,
		.e-close-bar {
			display: none !important; } } }

.scene-counter {
	width: 187px;
	height: 187px;
	position: fixed;
	left: 20px;
	top: 80px;
	z-index: 102;
	background: url('../img/scene-counter01.png') 0 0 no-repeat;

	.f-popup &,
	.f-stand-item & {
		display: none; }

	.t-hall2 & {
		background-image: url('../img/scene-counter02.png'); }

	.t-hall3 & {
		background-image: url('../img/scene-counter03.png'); }

	.t-hall4 & {
		background-image: url('../img/scene-counter04.png'); }

	&__link {
		position: absolute;
		display: block;
		width: 22px;
		height: 22px;
		background: rgba(255, 255, 255, 0);
		border-radius: 50%;
		left: 50%;
		top: 50%;
		margin-top: -11px;
		margin-left: -11px;

		&.t-1 {
			margin-top: 14px; }
		&.t-2 {
			margin-left: -35px; }
		&.t-3 {
			margin-top: -35px; }
		&.t-4 {
			margin-left: 14px; } } }

.l-content-controler {
		position: fixed;
		// position: absolute
		z-index: 102;
		width: 100%;
		bottom: 0;
		height: 60px;
		background-color: #1b1d1e;
		// background-image: -moz-linear-gradient( 90deg, rgb(215,222,224) 0%, rgb(215,222,224) 80%)
		// background-image: -webkit-linear-gradient( 90deg, rgb(215,222,224) 0%, rgb(215,222,224) 80%)
		// background-image: -ms-linear-gradient( 90deg, rgb(215,222,224) 0%, rgb(215,222,224) 80%)
		box-shadow: 0px -1px 3px 0px rgba(0, 0, 0, 0.2),0px -6px 20px 0px rgba(0, 0, 0, 0.2);
		transition: .5s;

		&:after {
			content: '';
			position: absolute;
			width: 100%;
			left: 0;
			top: 60px;
			height: 60px;
			background: #000;
			opacity: .4; }

		.i-down {
			transition: .5s; }

		body.f-stand-item &,
		body.f-popup & {
			display: none; }
		.e-open-bar {
			display: none; }
		.f-interface_hide & {
			bottom: -60px;
			&:after {
				display: none; }

			.e-open-bar {
				display: block;
				position: fixed;
				bottom: 0;
				right: 50%;
				margin-right: -560px;
				background-color: rgba(0,0,0,.6);
				padding: 5px 20px; } } }

.l-header {
	transition: .5s;
	.f-interface_hide & {
		top: -100px; } }
