.b-contorl-btn {
	display: table;
	float: right;
	height: 60px;

	> span,
	> a {
		display: table-cell;
		vertical-align: middle;
		padding: 0 12px;
		cursor: pointer;
		text-align: center;
		text-transform: uppercase;
		font-size: 10px;

		span {
			display: block; } } }

.t-min .e-scale-minus,
.t-max .e-scale-plus {
	cursor: default;
	color: $text-color;
	&:hover .icon {
		color: $text-color; } }

.icon {
	display: inline-block;
	width: 20px;
	height: 20px;
	color: $text-color;
	padding-top: 3px;
	padding-bottom: 3px;
	transition: color .2s;

	.b-contorl-btn__item.f-active &,
	.b-contorl-btn__item:hover &,
	&:hover {
		color: $active-color; }
	&.i-close {
		width: 17px;
		height: 17px;
		padding-top: 4px;
		padding-bottom: 4px; }
	&.i-down,
	&.i-up {
		width: 15px;
		height: 9px;
		padding-top: 8px;
		padding-bottom: 8px; }
	&.i-minus,
	&.i-plus {
		width: 25px;
		height: 25px;
		padding-bottom: 1px; }
	&.i-pause {
		width: 15px;
		height: 17px;
		padding-top: 4px;
		padding-bottom: 4px; }
	&.i-play {
		width: 13px;
		height: 17px;
		padding-top: 4px;
		padding-bottom: 4px; } }
