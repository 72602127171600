body {
	background-color: #000; }

#loader {
	background: url('../img/loader-bg.jpg') 0 0 no-repeat;
	background-size: cover;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 1200;
	display: none;

	.loader-holder {
		width: 484px;
		position: absolute;
		top: 50%;
		margin-top: -250px;
		left: 50%;
		margin-left: -242px; }

	.b-gerb-b {
		color: #fff;
		margin: 0 auto 122px;
		text-align: center;
		font-size: 14px;
		line-height: 17px;
		text-transform: uppercase;

		img {
			margin-bottom: 6px; } }

	.b-logo {
		width: 484px;
		height: 102px;
		background: url('../img/logo-b.png') 0 0 no-repeat;
		float: none;
		margin-bottom: 40px; }

	.b-loader-bar {
		margin-top: 93px;
		width: 82%;
		margin-left: auto;
		margin-right: auto; }

	.b-loader-bar-holder {
		position: relative;
		height: 2px;
		background-color: rgb(59, 67, 69);

		span {
			display: block;
			position: absolute;
			background-color: $active-color;
			width: 1%;
			height: 2px;
			transition: .1s;

			&:after {
				content: '';
				position: absolute; } } }


	.b-loader-bar-info {
		text-align: center;
		color: #3b4345;
		font-size: 26px;
		margin-top: 21px;
		font-style: italic; } }
